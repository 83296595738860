<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>权限列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}<span
                        v-if="control_p_info.control_name != ''">【{{ control_p_info.control_name }}】</span>
                </h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button icon="el-icon-refresh-left" size="medium" type="primary" @click="reload">返回
                        </el-button>
                        <el-button v-if="is_auth('home.it.savecontrol')" icon="el-icon-plus" size="medium"
                                   type="primary" @click="addPage">添加
                        </el-button>
                        <el-button v-if="is_auth('home.it.controlupdate')"
                                   :icon="updateing?'el-icon-loading':'el-icon-refresh'" size="medium"
                                   :disabled="updateing"
                                   type="primary" @click="controlupdate">更新权限
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    v-loading="loading"
                    size="medium"
                    :data="tableData"
                    border
                    style="width: 100%">
                <el-table-column prop="control_name" label="权限名称" width="180"></el-table-column>
                <el-table-column prop="control_remarks" label="权限描述" width="320">
                    <template slot-scope="scope">
                        <span v-if="scope.row.control_remarks == ''">-</span>
                        <span v-else>{{ scope.row.control_remarks }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="control_url" label="菜单url" width="240">
                    <template slot-scope="scope">
                        <span v-if="scope.row.control_url == ''">-</span>
                        <span v-else>{{ scope.row.control_url }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="icon" label="icon">
                    <template slot-scope="scope">
                        <span v-if="scope.row.icon == ''">-</span>
                        <span v-else><i :class="scope.row.icon"></i></span>
                    </template>
                </el-table-column>
                <el-table-column prop="is_menu" label="是否是菜单" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_menu == 1">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column prop="api_name" label="api_name" width="240">
                    <template slot-scope="scope">
                        <span v-if="scope.row.api_name == ''">-</span>
                        <span v-else>{{ scope.row.api_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="is_login" label="是否需要登录" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_login == 1">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column prop="is_public" label="是否公用">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_public == 1">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column prop="is_see" label="是否可见">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_see == 1">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderby" label="排序（升序）" width="120"></el-table-column>
                <el-table-column prop="apps_uuid" label="应用uuid" width="320"></el-table-column>
                <el-table-column prop="state" label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state == -1" style="color: #fa541c;">删除</span>
                        <span v-if="scope.row.state == 0">初始</span>
                        <span v-if="scope.row.state == 1" style="color: #40a9ff;">正常</span>
                        <span v-if="scope.row.state == 2" style="color: #fa8c16;">禁用</span>
                    </template>
                </el-table-column>
                <el-table-column prop="app_type" label="系统">
                    <template slot-scope="scope">
                        <span v-if="scope.row.app_type == 1" style="color: #fa8c16;">商家</span>
                        <span v-if="scope.row.app_type == 2" style="color: #40a9ff;">OSS</span>
                        <span v-if="scope.row.app_type == 3" style="color: #73d13d;">客户</span>
                        <span v-if="scope.row.app_type == 4" style="color: #13c2c2;">教练</span>
                    </template>
                </el-table-column>
                <el-table-column prop="date" label="操作" width="280" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="gotolink(scope.row)">查看下一层</el-button>
                        <el-button v-if="is_auth('home.it.savecontrol')" size="mini" @click="modify(scope.row)">修改
                        </el-button>
                        <el-button v-if="is_auth('home.it.getapidoc')" size="mini" @click="getapidoc(scope.row)">
                            查看api文档
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--api文档-->
            <el-dialog v-if="is_auth('home.it.getapidoc')"
                       :title="apidoc.name"
                       :visible.sync="seeapidoc"
                       width="80%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >
                <mavon-editor style="min-height: 600px"
                              @save="saveapidoc(1)"
                              v-model="apidoc.content"
                              :boxShadow="false"
                />
                <!--                <mavon-editor-->
                <!--                        :value="apidoc.content"-->
                <!--                        :boxShadow="false"-->
                <!--                        :subfield = "false"-->
                <!--                        :defaultOpen = "'preview'"-->
                <!--                        :toolbarsFlag = "false"-->
                <!--                        :editable="false"-->
                <!--                        :scrollStyle="true"-->
                <!--                        :ishljs = "true"-->
                <!--                ></mavon-editor>-->
                <el-row style="margin: 10px 0px;">
                    <el-button v-if="is_auth('home.it.saveapidoc')" size="medium" type="primary" @click="saveapidoc">保
                        存
                    </el-button>
                    <el-button size="medium" @click="seeapidoc = false">取 消</el-button>
                </el-row>
            </el-dialog>
            <!--编辑-->
            <el-dialog v-if="is_auth('home.it.savecontrol')"
                       title="编辑权限"
                       :visible.sync="editPage"
                       width="80%"
                       :close-on-press-escape="false"
                       :close-on-click-modal="false"
            >

                <el-row>
                    <el-form size="medium" ref="form" label-width="120px">
                        <el-col :span="24">
                            <el-form-item label="父级权限">
                                <span v-if="control_p_info.control_name != ''">【{{
                                        control_p_info.control_name
                                    }}】</span>
                                <span v-else>【0】</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="父级id">
                                <el-input v-model="info.control_uuid_p" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="权限名称">
                                <el-input v-model="info.control_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="权限描述">
                                <el-input v-model="info.control_remarks"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="菜单url">
                                <el-input v-model="info.control_url"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="icon">
                                <el-input v-model="info.icon"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="是否是菜单">
                                <el-input v-model="info.is_menu"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="排序，升序">
                                <el-input v-model="info.orderby"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="api_name">
                                <el-input v-model="info.api_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="是否需要登录">
                                <el-input v-model="info.is_login"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="是否公用">
                                <el-input v-model="info.is_public"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="是否可见">
                                <el-input v-model="info.is_see"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="状态">
                                <el-input v-model="info.state"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="应用uuid">
                                <el-input v-model="info.apps_uuid" :disabled="true"></el-input>
                            </el-form-item>
                        </el-col>

                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button size="medium" type="primary" @click="save">确 定</el-button>
                    <el-button size="medium" @click="editPage = false">取 消</el-button>
                </el-row>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import {mavonEditor} from 'mavon-editor'

export default {
    components: {
        'mavon-editor': mavonEditor
    },
    data() {
        return {
            updateing:false,
            seeapidoc: false,
            page_name: 'oss系统菜单',
            loading: true,
            control_uuid_p: '0',
            editPage: false,
            info: {},
            // 列表内容
            tableData: [],
            apidoc: {},
            control_p_info: {
                control_name: '',
                control_uuid: '0',
            }
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
        this.getlist()     // 获取列表数据
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 刷新
        reload() {
            location.reload()
        },
        // 初始化
        init() {
            this.info = {
                control_uuid: '',
                control_uuid_p: '0',
                control_remarks: '',
                control_url: '',
                api_name: '',
                is_login: '1',
                apps_uuid: '',
                orderby: '999',
                is_menu: '0',
                icon: '',
                is_see: '1',
                is_public: '0',
                state: '1',
                app_type: '2'
            }
            // api文档内容
            this.apidoc = {
                name: '',
                uuid: '',
                content: '',
            }
        },
        // 获取列表
        getlist() {
            this.loading = true
            let postdata = {
                api_name: 'home.it.getcontrollist',
                token: this.Tool.get_l_cache('token'),
                app_type: '2',
                control_uuid_p: this.control_uuid_p,
            }
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                // console.log('返回数据', json)
                if (json.code === 0) {
                    this.tableData = json.data.list
                } else {
                    this.tableData = []
                    this.$message.error(json.message);
                }
            })
        },
        //修改
        modify(row) {
            // console.log(row)
            this.info = row
            this.editPage = true
        },
        // 添加
        addPage() {
            this.init()
            this.info.control_uuid_p = this.control_p_info.control_uuid
            this.info.apps_uuid = '4df60182-b307-11eb-8861-00163e20d10e'
            this.editPage = true
        },
        // 查看
        gotolink(row) {
            this.control_p_info.control_name = row.control_name
            this.control_p_info.control_uuid = row.control_uuid
            this.control_uuid_p = row.control_uuid
            this.getlist();
        },
        // 保存
        save() {
            let postdata = {
                api_name: 'home.it.savecontrol',
                token: this.Tool.get_l_cache('token'),
                form: this.info
            }
            // console.log('请求数据', postdata);
            this.Tool.post_data('oss', postdata, (json) => {
                // console.log('返回数据', json)
                if (json.code === 0) {
                    this.editPage = false

                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 更新应用
        controlupdate() {
            let postdata = {
                api_name: 'home.it.controlupdate',
                token: this.Tool.get_l_cache('token'),
            }
            this.updateing = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.updateing = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 获取文档
        getapidoc(data) {
            let postdata = {
                api_name: "home.it.getapidoc",
                token: this.Tool.get_l_cache('token'),
                uuid: data.control_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.seeapidoc = true
                this.loading = false
                this.apidoc = {
                    name: `接口文档【${data.control_name}】`,
                    uuid: data.control_uuid,
                    content: json.content,
                }
            })
        },
        // 保存文档
        saveapidoc(noclose) {
            let postdata = {
                api_name: "home.it.saveapidoc",
                token: this.Tool.get_l_cache('token'),
                uuid: this.apidoc.uuid,
                content: this.apidoc.content
            }
            if (noclose !== 1) {
                this.seeapidoc = false
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '保存成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                        }
                    });

                } else {
                    this.$message.error(json.message);
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-table th > .cell {
    display: inline-block;
    white-space: nowrap;
    word-break: keep-all;
    text-overflow: unset;
}
</style>
